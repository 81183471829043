import React from "react";

import Image from "next/image";
import whatsImage from "public/assets/img/whatsapp/whatsapp-icon.svg";

import { WhatsAppContainer } from "./styles";
import { contactPhone, message } from "@/constants/messaging";

export const WhatsAppButton = () => {
  const handleWhatAppButtonClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      const link = `whatsapp://send?phone=${contactPhone}&text=${message}`;
      window.open(link);
    } else {
      const link = `https://web.whatsapp.com/send?phone=${contactPhone}&text=${message}`;
      window.open(link, "_blank");
    }
  };

  return (
    <WhatsAppContainer>
      <Image
        src={whatsImage}
        alt="Logo do Whatsapp"
        width={70}
        height={70}
        onClick={handleWhatAppButtonClick}
      />
    </WhatsAppContainer>
  );
};
