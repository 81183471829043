export const getBrazilianGreeting = () => {
  const currentHour: number = new Date().getHours();

  if (currentHour >= 6 && currentHour < 12) {
    return "Bom dia";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Boa tarde";
  } else {
    return "Boa noite";
  }
};

export const BRLFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const loadHotjarScript = (): void => {
  (function(h, o, t, j, a, r) {
    (h as any).hj =
      (h as any).hj ||
      function() {
        ((h as any).hj.q = (h as any).hj.q || []).push(arguments);
      };
    (h as any)._hjSettings = { hjid: 3536138, hjsv: 6 };
    (a as any) = o.getElementsByTagName("head")[0] as HTMLHeadElement;
    (r as any) = o.createElement("script");
    (r as any).async = 1;
    (r as any).src = t + (h as any)._hjSettings.hjid + j + (h as any)._hjSettings.hjsv;
    (a as any).appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};
