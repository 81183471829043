import React, { useEffect, useRef } from "react";

interface Props {
  onOutsideClick: () => void;
  children: React.ReactElement;
  isActive: boolean;
}

export const OutsideClick: React.FC<Props> = ({
  onOutsideClick,
  children,
  isActive,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        isActive
      ) {
        onOutsideClick();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isActive) {
        onOutsideClick();
      }
    };

    // Bind the event listeners when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    // Cleanup the event listeners when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isActive, onOutsideClick]);

  return <div ref={containerRef}>{children}</div>;
};
