const tokens = {
  breakpoints: {
    s: "767px",
    m: "1023px",
    l: "1439px",
    xl:"1440px"
  },
  screens: {
    s: {
      max: "767px",
    },
    m: {
      min: "768px",
      max: "1023px",
    },
    l: {
      min: "1024px",
      max: "1439px",
    },
    xl: {
      min: "1440px",
    },
  },
  mediaQueries: {
    s: "@media screen and (max-width: 767px)",
    m: "@media screen and (min-width: 768px) and (max-width: 1023px)",
    l: "@media screen and (min-width: 1024px) and (max-width: 1439px)",
    xl: "@media screen and (min-width: 1440px)",
  },
  colors: {
    primary: "#EA1D2C",
    primaryDark: "#C31835",
    primaryLight: "#EF5753",
    primaryLightest: "#FCEBEA",
    secondary: "#FFFFFF",
    secondaryDark: "#C31835",
    secondaryLight: "#EF5753",
    secondaryLightest: "#FCEBEA",
    white: "#FFFFFF",
    black: "#3E3E3E",
    gray: "#DCDCDC",
    grayLight: "#F2F2F2",
    grayDarker: "#A6A6A6",
    grayDarkest: "#717171",
    information: "#22A1D0",
    informationLight: "#33B5E5",
    informationLightest: "#DBF2FB",
    focus: "#4d90fe",
    attentionDarkest: "#BD7E27",
    attentionDark: "#D9912D",
    attention: "#E7A74E",
    attentionLight: "#FFF2D1",
    attentionLightest: "#FFFBF4",
    positiveDarkest: "#27824C",
    positiveDarker: "#409D66",
    positive: "#50A773",
    positiveLight: "#DEF0E5",
    positiveLightest: "#EFF9F3",
    errorDarkest: "#B74323",
    errorDark: "#DF5C38",
    error: "#FF7752",
    errorLight: "#FFD6CB",
    errorLightest: "#FFF5F2",
    informativeDarkest: "#1A87AF",
    informativeDark: "#22A1D0",
    informative: "#33B5E5",
    informativeLight: "#DBF2FB",
    informativeLightest: "#EEFBFF",
    bgPromoLight: "#AEBFC9",
    bgPromoLightest: "#C8D1D7",
    star: "#FCBB00",
    promotion: "#2E6788",
    league: "rgb(245, 171, 78)",
    wildRift: "rgb(67, 245, 171)",
    valorant: "rgb(245, 67, 81)",
  },
  durations: {
    slowly: "400ms",
    promptly: "250ms",
    quickly: "100ms",
    immediately: "50ms",
    instantly: "0ms",
  },
  timingFunctions: {
    ease: "ease",
    easeIn: "ease-in",
    easeInOut: "ease-in-out",
  },
  radii: {
    s: "4px",
    m: "6px",
    l: "50%",
  },
  shadows: {
    elevate: "0px 2px 6px rgba(0,0,0,0.24)",
    focus: "0 0 0 3px #4d90fe",
    focusLight: "0 0 0 2px #4d90fe",
    none: "none",
  },
  space: {
    none: "0",
    tiny: "2px",
    smallest: "4px",
    smaller: "8px",
    small: "12px",
    regular: "16px",
    large: "24px",
    larger: "32px",
    huge: "40px",
    largest: "48px",
    loose: "56px",
    looser: "64px",
    loosest: "80px",
  },
  fontSizes: {
    xl: "32px",
    l: "24px",
    txl: "20px",
    m: "18px",
    s: "16px",
    tsm: "14px",
    xs: "12px",
  },
  fontWeights: {
    bold: 700,
    medium: 500,
    regular: 400,
    light: 300,
  },
  fonts: {
    primary: "iFood RC Textos, sans-serif",
  },
  font: {
    family: {
      ifood: {
        body: "iFood RC Textos, sans-serif",
        headings: "iFood RC Títulos, sans-serif",
      },
    },
  },
  lineHeights: {
    xl: "48px",
    l: "32px",
    m: "24px",
    s: "16px",
    xs: "8px",
  },
};

export default tokens;
