import tokens from "@/tokens";
import styled from "styled-components";

export const MobileButton = styled.div`
  display: flex;
  flex-direction: row;

  position: absolute;
  top: 12px;
  right: 20px;

  button {
    border: none;
    margin-left: auto;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    z-index: 100;
    cursor: pointer;
  }
`;

export const MobileNavigation = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;

  .logo {
    max-width: 90px;
    height: auto;
    a {
      height: 65px;
    }
  }
`;

export const MobileMenuItems = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;

  ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto 0 122px;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      margin-top: 32px !important;
      width: 100%;
    }

    .acquireButton {
      margin-top: 16px;
    }
  }
  @media (max-width: 1500px) {
    ul {
      margin: 0 auto 0 65px;
    }
  }
  ul li {
    display: block;
    position: relative;
    list-style: none;
  }
  ul li a {
    font-size: 15px;
    font-weight: var(--tg-fw-bold);
    text-transform: uppercase;
    color: var(--tg-heading-color);
    font-family: var(--tg-heading-font-family);
    padding: 38px 23px;
    display: block;
    line-height: 1;
    position: relative;
    letter-spacing: 0.8px;
    z-index: 1;
  }
  ul li .sub-menu {
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 230px;
    border: 1px solid var(--tg-border-2);
    background: var(--tg-common-color-gray);
    margin: 0;
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
    transform-origin: 0 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0;
    padding: 18px 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
  }
  ul li .sub-menu .sub-menu {
    right: auto;
    left: 100%;
    top: 0;
  }
  ul li .sub-menu li {
    margin-left: 0;
    text-align: left;
    display: block;
  }
  ul li .sub-menu li a {
    padding: 9px 15px 9px 25px;
    line-height: 1.4;
    font-weight: var(--tg-fw-bold);
    color: var(--tg-heading-color);
    text-transform: uppercase;
    letter-spacing: 0.8px;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  ul li .sub-menu li a::before {
    content: "";
    display: block;
    width: 0;
    height: 7px;
    background-color: var(--tg-theme-primary);
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
    -o-border-radius: 0 5px 0 0;
    -ms-border-radius: 0 5px 0 0;
    border-radius: 0 5px 0 0;
    margin: 7px 9px 0 0;
    -webkit-transition: width 0.2s linear;
    -moz-transition: width 0.2s linear;
    -ms-transition: width 0.2s linear;
    -o-transition: width 0.2s linear;
    transition: width 0.2s linear;
  }
  ul li .sub-menu li:hover > a::before,
  ul li .sub-menu li.active > a::before {
    width: 7px;
  }
  ul li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  > ul > li > a::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 42px;
    height: 1px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    background: var(--tg-theme-primary);
    margin: 0 auto;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  > ul > li.active > a,
  > ul > li:hover > a {
    background: linear-gradient(
      to right,
      var(--tg-theme-primary-pallet),
      var(--tg-theme-primary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  > ul > li.active > a::before,
  > ul > li:hover > a::before {
    opacity: 1;
    -webkit-transform: translateY(-50%) rotate(-40deg);
    -moz-transform: translateY(-50%) rotate(-40deg);
    -ms-transform: translateY(-50%) rotate(-40deg);
    -o-transform: translateY(-50%) rotate(-40deg);
    transform: translateY(-50%) rotate(-40deg);
  }
  .tgmenu__main-menu li.menu-item-has-children .dropdown-btn {
    display: none;
  }

  
  .arrow{
    position:relative;
 

    &::before{
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
`;
