import { LessonType, PlanType } from "@/components/CoachingDetails/types";
import { Point } from "@/components/PointsModal";

export const contactPhone = "557199545176";

export const message = "Olá estou no site e gostaria de tirar uma duvida.";

export type TogglesType = {
  id: number;
  label: string;
  percentage: number;
  isActive: boolean;
}[];

export type Toggle = {
  id: number;
  label: string;
  percentage: number;
  isActive: boolean;
};

export const detailsMessage = (
  greeting: string,
  service: string,
  game: string,
  eloData: any,
  price: string,
  toggles: TogglesType,
  primaryRole: any,
  secondaryRole: any,
  currentPoints: Point
) => `
\n *${greeting}, segue meu pedido de serviço para o Elojob RISE:*
---------------------------------------------------------
*DADOS DO PEDIDO*
---------------------------------------------------------
\n*Jogo:* ${game}
\n*Serviço:* ${service}
\n*Elo atual:* ${eloData.currentElo.displayName} - ${eloData.currentLeague}
\n*Elo desejado:* ${eloData.desiredElo.displayName} - ${eloData.desiredLeague}
\n*Pontos atuais:* ${currentPoints.label}
\n*Valor:* ${price}
---------------------------------------------------------
*ADICIONAIS DO PEDIDO*
---------------------------------------------------------
\n*Role principal:* ${primaryRole.label}
\n*Role secundária:* ${secondaryRole.label}

${toggles
  .filter((__aditional) => __aditional.isActive === true)
  .map((__add) => `${__add.label}`)
  .join("\n\n")}
`;

export const singleVictoriesMessage = (
  greeting: string,
  service: string,
  game: string,
  eloData: any,
  price: string,
  quantityOfMatches: number,
  lessonType: LessonType,
  toggles: TogglesType,
  primaryRole: any,
  secondaryRole: any
) => `
\n *${greeting}, segue meu pedido de serviço para o Elojob RISE:*
---------------------------------------------------------
*DADOS DO PEDIDO*
---------------------------------------------------------
\n*Jogo:* ${game}
\n*Serviço:* ${service}
\n*Elo atual:* ${eloData.currentElo.displayName}
\n*Estilo de Vit´roias:* ${lessonType.label}
\n*Quantidade de partidas:* ${quantityOfMatches}
\n*Valor:* ${price}
---------------------------------------------------------
*ADICIONAIS DO PEDIDO*
---------------------------------------------------------
\n*Role principal:* ${primaryRole.label}
\n*Role secundária:* ${secondaryRole.label}

${toggles
  .filter((__aditional) => __aditional.isActive === true)
  .map((__add) => `${__add.label}`)
  .join("\n\n")}
`;

export const md5Message = (
  greeting: string,
  service: string,
  game: string,
  eloData: any,
  price: string,
  quantityOfMatches: number,
  lessonType: LessonType,
  toggles: TogglesType,
  primaryRole: any,
  secondaryRole: any
) => `
\n *${greeting}, segue meu pedido de serviço para o Elojob RISE:*
---------------------------------------------------------
*DADOS DO PEDIDO*
---------------------------------------------------------
\n*Jogo:* ${game}
\n*Serviço:* ${service}
\n*Elo atual:* ${eloData.currentElo.displayName}
\n*Estilo MD5 atual:* ${lessonType.label}
\n*Quantidade de partidas:* ${quantityOfMatches}
\n*Valor:* ${price}
---------------------------------------------------------
*ADICIONAIS DO PEDIDO*
---------------------------------------------------------
\n*Role principal:* ${primaryRole.label}
\n*Role secundária:* ${secondaryRole.label}

${toggles
  .filter((__aditional) => __aditional.isActive === true)
  .map((__add) => `${__add.label}`)
  .join("\n\n")}
`;

export const coachingMessage = (
  greeting: string,
  service: string,
  game: string,
  price: string,
  quantityOfLessons: number,
  lessonType: LessonType,
  plan: PlanType
) => `
\n *${greeting}, segue meu pedido de serviço para o Elojob RISE:*\n 
---------------------------------------------------------
*DADOS DO PEDIDO*
---------------------------------------------------------
\n*Jogo:* ${game}\n
\n*Serviço:* ${service}\n
\n*Valor:* ${price}\n
---------------------------------------------------------
*ADICIONAIS DO PEDIDO*
---------------------------------------------------------
\n*Plano escolhido* ${plan?.title}
\n*Número de aulas:* ${quantityOfLessons}
\n*Tipo de aula:* ${lessonType?.label}
`;

export const mercadoPagoDescription = (
  service: string,
  game: string,
  eloData: any,
  toggles: TogglesType,
  primaryRole: any,
  secondaryRole: any,
  quantityOfMatches?: number,
  selectedBestOfFiveType?: {
    value: string;
    label: string;
  }
) => {
  let description = "";

  switch (service) {
    case "md5":
      description = `${game}|${service}|Elo atual: ${
        eloData.currentElo.displayName
      }|Role main:${primaryRole.label}|Role sec:${
        secondaryRole.label
      }|Partidas:${quantityOfMatches}|Tipo MD5:${selectedBestOfFiveType?.label}|
      ${toggles
        .filter((__aditional) => __aditional.isActive === true)
        .map((__add) => `${__add.label}`)
        .join("|")}
      `;
      break;
    case "elojob":
      description = `
        ${game}|${service}|Elo atual: ${eloData.currentElo.displayName}-${
        eloData.currentLeague
      }|Desejado: ${eloData.desiredElo.displayName}-${
        eloData.desiredLeague
      }|Role main:${primaryRole.label}|Role sec:${secondaryRole.label}
        ${toggles
          .filter((__aditional) => __aditional.isActive === true)
          .map((__add) => `${__add.label}`)
          .join("|")}
        `;
      break;
    case "duoboost":
      description = `
        ${game}|${service}|Elo atual: ${eloData.currentElo.displayName}-${
        eloData.currentLeague
      }|Desejado: ${eloData.desiredElo.displayName}-${
        eloData.desiredLeague
      }|Role main:${primaryRole.label}|Role sec:${secondaryRole.label}
        ${toggles
          .filter((__aditional) => __aditional.isActive === true)
          .map((__add) => `${__add.label}`)
          .join("|")}
        `;
      break;
  }

  description = description.slice(0, 256);

  return description;
};

export const formattedPaymentStatusMessage = (
  greeting: string,
  service: string,
  game: string,
  eloData: any,
  price: string,
  toggles: TogglesType,
  primaryRole: any,
  secondaryRole: any,
  currentPoints: Point
) => `
\n *${greeting}, segue meu pedido de serviço para o Elojob RISE:*
---------------------------------------------------------
*DADOS DO PEDIDO*
---------------------------------------------------------
\n*Jogo:* ${game}
\n*Serviço:* ${service}
\n*Elo atual:* ${eloData.currentElo.displayName} - ${eloData.currentLeague}
\n*Elo desejado:* ${eloData.desiredElo.displayName} - ${eloData.desiredLeague}
\n*Pontos atuais:* ${currentPoints.label}
\n*Valor:* ${price}
---------------------------------------------------------
*ADICIONAIS DO PEDIDO*
---------------------------------------------------------
\n*Role principal:* ${primaryRole.label}
\n*Role secundária:* ${secondaryRole.label}

${toggles
  .filter((__aditional) => __aditional.isActive === true)
  .map((__add) => `${__add.label}`)
  .join("\n\n")}
`;
