import React, { HTMLAttributeAnchorTarget } from "react";
import { CustomButton, CustomButtonNoLink } from "./styles";

type CustomAcquireButtonProps = {
  link?: string;
  className?: string;
  customcolor?: string;
  styleLogin? : boolean;
  customhovercolor?: string;
  onClick?: () => void;
  children?: any;
  type?: "button" | "submit" | "reset";
  id?: any;
  ref?: any;
  disabled?: boolean;
  fontSize?: number;
  target?: HTMLAttributeAnchorTarget;
};

export const CustomAcquireButton: React.FC<CustomAcquireButtonProps> = ({
  link,
  customcolor,
  styleLogin,
  customhovercolor,
  className,
  onClick,
  children,
  type,
  id,
  ref,
  disabled,
  fontSize,
  target
}) => {
  if (link) {
    return (
      <CustomButton
        href={link}
        customcolor={customcolor}
        className={className}
        customhovercolor={customhovercolor}
        id={id}
        ref={ref}
        fontSize={fontSize}
        $styleLogin={styleLogin}
        target={target}
      >
        <span>{children}</span>
      </CustomButton>
    );
  }

  return (
    <CustomButtonNoLink
      customcolor={customcolor}
      customhovercolor={customhovercolor}
      className={className}
      onClick={onClick}
      type={type}
      id={id}
      ref={ref}
      disabled={disabled} 
          
    >
      <span>{children}</span>
    </CustomButtonNoLink>
  );
};
