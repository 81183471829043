import React from "react";
import Head from "next/head";

import { ISEO } from "./SEO.interface";

export const SEO = ({ meta, keywords, image, url }: ISEO) => {
  const gtmScript = `
  (function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;
    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', "GTM-NRVSBHHT");
`;

  return (
    <Head>
      {meta && <meta name="description" content={meta} />}
      {image && <meta name="image" content={image as string} />}
      <meta name="MobileOptimized" content="320" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="theme-color" content="#ed8f03" />
      <meta name="msapplication-TileColor" content="#121214" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta name="google" content="translate" />
      <meta name="author" content="Elojob RISE"></meta>

      <meta property="og:description" content={meta} />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Elojob RISE" />
      {image && <meta property="og:image" content={image as string} />}
      {image && (
        <meta property="og:image:secure_url" content={image as string} />
      )}
      <meta property="og:image:alt" content="Thumbnail" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:url" content={url} />
      {keywords && <meta property="keywords" content={keywords} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="application-name" content="Elojob RISE" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Elojob Rise" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="msapplication-tap-highlight" content="no" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff" />
      <script
        id="new-google-tag-manager"
        dangerouslySetInnerHTML={{ __html: gtmScript }}
      />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9799957321409855"
        crossOrigin="anonymous"
      ></script>
    </Head>
  );
};
