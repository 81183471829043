import React from "react";
import { Container, Child } from "./styles";

type MainSectionProps = {
  children: any;
};

export const MainSection: React.FC<MainSectionProps> = ({ children }) => {
  return (
    <Container>
      <Child>{children}</Child>
    </Container>
  );
};
