import styled from "styled-components";

export const LoadingContainer = styled.div<{
    isVisible?: boolean;
  }>`
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: #1b1f28;
        opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
        visibility: ${props => (props.isVisible ? "visible" : "hidden")};
        transition: opacity 0.5s, visibility 0s 0.5s; /* Adicionando um atraso na transição de visibility */
        -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
        -moz-transition: opacity 0.5s, visibility 0s 0.5s;
        -o-transition: opacity 0.5s, visibility 0s 0.5s;

    .sk-folding-cube {
        margin: 20px auto;
        width: 40px;
        height: 40px;
        position: absolute;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        left: 0px;
        right: 0px;
        top: 50%;
        margin-top: -20px;
    }

    .sk-folding-cube .sk-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);  
    }

    .sk-folding-cube .sk-cube:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
        animation: sk-foldCubeAngle 2.4s infinite linear both;
        -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        background-color: #ff8800!important;
    }

    .sk-folding-cube .sk-cube2 {
        -webkit-transform: scale(1.1) rotateZ(90deg);
        transform: scale(1.1) rotateZ(90deg);
    }

    .sk-folding-cube .sk-cube2:before {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .sk-folding-cube .sk-cube4 {
        -webkit-transform: scale(1.1) rotateZ(270deg);
        transform: scale(1.1) rotateZ(270deg);
    }

    .sk-folding-cube .sk-cube4:before {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
    }

    .sk-folding-cube .sk-cube3 {
        -webkit-transform: scale(1.1) rotateZ(180deg);
        transform: scale(1.1) rotateZ(180deg);
    }
    
    .sk-folding-cube .sk-cube3:before {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }


    @keyframes sk-foldCubeAngle{
        0%, 10% {
            -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
            opacity: 0;
        }
        25%, 75% {
            -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
            opacity: 1;
        }
        90%, 100% {
            -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
            opacity: 0;
        }
    }
    
    @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
 
`;