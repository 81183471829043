import React, { createContext, useEffect, useState } from "react";

import firebase from "firebase/auth";

interface ContextProps {
  user: UserData | undefined;
  setUser: (user: UserData) => void;
  isLoggedIn: boolean;
  loadedUser: boolean;
}

type UserData = (firebase.User & { isAdmin: boolean }) | null;

export const UserContext = createContext<ContextProps>({
  user: null,
  setUser: () => {},
  isLoggedIn: true,
  loadedUser: false,
});

type UserContextProps = {
  children: any;
};

export const UserProvider: React.FC<UserContextProps> = ({ children }) => {
  const [user, setUserData] = useState<UserData>();
  const [isLoggedIn, setIsLoogedIn] = useState(true);
  const [loadedUser, setLoadedUser] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      handleAdmin();
    }
    async function handleAdmin() {
      const result = await fetch("/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: user?.uid }),
      });
      const response: UserData = await result.json();
      if (response) {
        setUserData({ ...user, isAdmin: response?.isAdmin } as UserData);
        setLoadedUser(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  const setUser = (userData: UserData) => {
    setUserData(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    setIsLoogedIn(true);
  };

  useEffect(() => {
    const loggedUserData = localStorage.getItem("user");
    if (loggedUserData && loggedUserData !== "null" && !user) {
      setUserData(JSON.parse(loggedUserData));
      return;
    }
    if (!user) {
      setIsLoogedIn(false);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, loadedUser }}>
      {children}
    </UserContext.Provider>
  );
};
