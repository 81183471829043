import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@/styles/globals.scss";

import { MainSection } from "@/components/MainSection";
import { SEO } from "@/components/SEO";
import { WhatsAppButton } from "@/components/WhatsAppButton";
import { MobileMenu } from "@/components/MobileMenu";
import { Loading } from "@/components/Loading";
import Script from "next/script";
import {
  GOOGLE_TAG_MANAGER_ID,
  GOOGLE_TRACKING_ID_ADS,
  GOOGLE_TRACKING_ID_ANALYTICS,
} from "@/constants/google";

import { loadHotjarScript } from "@/utils";
import { UserProvider } from "@/contexts/userContext";
import FacebookPixel from "@/components/FacebookPixel";

const DynamicHeader = dynamic(() =>
  import("../components/Header").then((mod) => mod.Header)
);
const DynamicFooter = dynamic(() =>
  import("../components/Footer").then((mod) => mod.Footer)
);

export default function App({ Component, pageProps }: AppProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  const [showFooter, setShowFooter] = React.useState<boolean>(false);

  const handleMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  const router = useRouter();

  useEffect(() => {
    setShowMobileMenu(false);
  }, [router.asPath]);

  useEffect(() => {
    const route = router.asPath;
    if (route.includes("/login")) {
      return setShowFooter(false);
    }
    return setShowFooter(true);
  }, [router.asPath]);

  useEffect(() => {
    loadHotjarScript();
  }, []);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id="${GOOGLE_TRACKING_ID_ANALYTICS}"`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GOOGLE_TRACKING_ID_ANALYTICS}');
        `}
      </Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          gtag('config', '${GOOGLE_TRACKING_ID_ADS}');
        `}
      </Script>

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <FacebookPixel />

      <SEO
        keywords="elo job, elojob, elo boost, eloboost, duo boost, duoboost, elojob barato, elojob RISE, high elo, md5, vitorias, lol, league of legends, valorant, wild rift"
        url="https://elojobrise.com"
        meta="	
        ELOJOB RISE é o serviço ideal para você subir o elo da sua conta! Boosting (Elojob), MD5 , Duo Boost, Coach, Contas lol, Elojob LoL, Valorant e Wild Rift."
        image="https://elojobrise.com/assets/img/header/logo.png"
      />
      <UserProvider>
        <Loading />
        {showFooter && <DynamicHeader handleMobileMenu={handleMobileMenu} />}
        <MobileMenu open={showMobileMenu} handleMobileMenu={handleMobileMenu} />
        <MainSection>
          <Component {...pageProps} />
        </MainSection>
        <WhatsAppButton />
        <ToastContainer />
      </UserProvider>
      {showFooter && <DynamicFooter />}
      <VercelAnalytics />
      <SpeedInsights />
    </>
  );
}
