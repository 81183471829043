import styled from "styled-components";
import Link from "next/link";

export const CustomButton = styled(Link)<{
  customcolor?: string;
  customhovercolor?: string;
  fontSize?: number;
  $styleLogin? : boolean;
}>`
 
${({ $styleLogin,customcolor }) =>
    $styleLogin 
      ? 
      
      `
      display: inline-block;
      position: relative;
      top: 5px;
      padding: 9px 15px 7px 47px !important;
      font-size: 18px !important;
      color: var(--tg-theme-primary);
      border: 1px solid #000;
      text-transform: uppercase;
      font-weight: 800;
      background: #1a1b1f;
      height: 36px !important;
      max-height: none;
      margin-top: 20px;
      line-height: 18px;
      position:relative;
    `
      
      : `
  
    position: relative;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    background: ${customcolor ? customcolor : "var(--tg-theme-primary)"};
    border-radius: 50px;
    color: #e3dfdf;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 16px 34px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;


    :hover span {
      color: ${customcolor ? customcolor : "#000"} !important;
      z-index: 3;
    }
    
    span{
      position:relative;
    }
  `
 }

 
  span {
    z-index: 3;
    place-self: center;
    transition: color 0.3s;
    font-weight: 800;
    font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
  }


  ${({ $styleLogin }) =>
  $styleLogin
    ?  
    ` :hover .loginButton span{
      color:#fff;
      }  
    `
    :

  ` :hover:before {
    left: 0;
    width: 100%;
    transform: skew(0); 
    transition: left 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      width 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
  }
 
  ` 
}

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 0;
    border-radius: 3px;
    transform: skew(-35deg);
    opacity: 1;
    background: ${({ customhovercolor }) =>
      customhovercolor ? `${customhovercolor}` : "#f1ecec"};
    z-index: 2;
    transition: left 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      width 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
      border-radius: 50px;
  }

 
  .loginButton .icon,.registerButton .icon{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ff8800;
    font-size: 15px;
    color: #eee;
    height: 34px;
    padding: 11px 12px !important;
  }

   
  .registerButton .icon{
    background-color: transparent;
   
  }


  .loginButton span, .registerButton span{
    color: var(--tg-theme-primary);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
  }

  .registerButton span{
    color:#fff !important;
  } 

`;

export const CustomButtonNoLink = styled.button<{
  customcolor?: string;
  customhovercolor?: string;
}>`
  height: 100%;
  display: flex;
  align-self: center;
  position: relative;
  font-size: 20px;
  height: 3rem;
  padding: 0.9rem 1.5rem;
  font-weight: 500;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  background-color: ${({ customcolor }) =>
    customcolor ? `${customcolor}` : "var(--tg-theme-primary)"};
  transition: color 0.3s, background-color 0.3s;
  border-radius: 3px;
  border-width: 0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;

  span {
    z-index: 3;
    place-self: center;
    transition: color 0.3s;
    color: #fff;
    font-weight: 600;
  }

  :disabled {
    background-color: rgb(82, 80, 87);
    cursor: unset;
    pointer-events: none;
  }
 
  :hover:before {
    left: 0;
    width: 100%;
    transform: skew(0);
    transition: left 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      width 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 0;
    border-radius: 3px;
    transform: skew(-35deg);
    opacity: 1;
    background: ${({ customhovercolor }) =>
      customhovercolor ? `${customhovercolor}` : "#f1ecec"};
    z-index: 2;
    transition: left 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      width 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.3s;
  }
`;
