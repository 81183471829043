import Image from "next/image";
import React from "react";
import Link from "next/link";

import { OutsideClick } from "@/components/OutsideClick";
import { useContext } from "react";
import { UserContext } from "@/contexts/userContext";

import closeImage from "public/assets/img/header/fechar.svg";

import { MobileButton, MobileMenuItems, MobileNavigation } from "./styles";
import { Avatar } from "../Avatar";

export const MobileMenu = ({
  open,
  handleMobileMenu,
}: {
  open: boolean;
  handleMobileMenu: () => void;
}) => {
  const { user, setUser } = useContext(UserContext);
  const isLoggedIn = !!user;

  return (
    <OutsideClick onOutsideClick={handleMobileMenu} isActive={open}>
      <div className={open ? "tgmobile__menu open" : "tgmobile__menu"}>
        <nav className="tgmobile__menu-box">
          <MobileButton>
            <button onClick={handleMobileMenu} name="Fechar menu">
              <Image
                src={closeImage}
                alt="Fechar menu"
                height={25}
                width={25}
              />
            </button>
          </MobileButton>
          <div className="nav-logo">
            <MobileNavigation>
              <MobileMenuItems>
                <ul className="navigation">
                  <li className="active menu-item-has-children">
                    <Link href="/">Início</Link>
                  </li>
                  <li>
                    <span>Elojob <span className="arrow"></span></span>
                    <ul className="sub-menu">
                      <li className="active">
                        <Link href="/contratar/elojob/league-of-legends">
                          League of Legends
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/elojob/wild-rift">
                          Wild Rift
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/elojob/valorant">Valorant</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <span>Duoboost <span className="arrow"></span></span>
                    <ul className="sub-menu">
                      <li className="active">
                        <Link href="/contratar/duoboost/league-of-legends">
                          League of Legends
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/duoboost/wild-rift">
                          Wild Rift
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/duoboost/valorant">
                          Valorant
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <span>Vitórias Avulsas <span className="arrow"></span></span>
                    <ul className="sub-menu">
                      <li className="active">
                        <Link href="/contratar/vitorias/league-of-legends">
                          League of Legends
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/vitorias/wild-rift">
                          Wild Rift
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/vitorias/valorant">
                          Valorant
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <span>MD5 <span className="arrow"></span></span>
                    <ul className="sub-menu">
                      <li className="active">
                        <Link href="/contratar/md5/league-of-legends">
                          League of Legends
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/md5/wild-rift">Wild Rift</Link>
                      </li>
                      <li>
                        <Link href="/contratar/md5/valorant">Valorant</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <span>Coach <span className="arrow"></span></span>
                    <ul className="sub-menu">
                      <li className="active">
                        <Link href="/contratar/coach/league-of-legends">
                          League of Legends
                        </Link>
                      </li>
                      <li>
                        <Link href="/contratar/coach/wild-rift">Wild Rift</Link>
                      </li>
                      <li>
                        <Link href="/contratar/coach/valorant">Valorant</Link>
                      </li>
                    </ul>
                  </li>

                  <Avatar/> 
                </ul>
              </MobileMenuItems>
            </MobileNavigation>
          </div>
        </nav>
      </div>
    </OutsideClick>
  );
};
