import React, { useContext } from "react";
import { AvatarContainer, UserWrap } from "./styles";
import { CustomAcquireButton } from "../CustomAcquireButton";
import { UserContext } from "@/contexts/userContext";
import { FaLock, FaRegEdit, FaTrophy } from "react-icons/fa";
import { IoMdArrowDropdown, IoIosExit } from "react-icons/io";

import Link from "next/link";
import defaultProfile from "public/assets/img/header/default-profile.webp";
import Image from "next/image";

export const Avatar = () => {
  const { user, setUser } = useContext(UserContext);

  const getStoreUser =
    typeof window !== "undefined" ? localStorage.getItem("user") : null;
  const storeUser = getStoreUser ? JSON.parse(getStoreUser) : null;

  const createdAt = storeUser?.createdAt
    ? new Date(parseInt(storeUser.createdAt, 10))
    : null;

  const month = createdAt?.toLocaleString("default", { month: "short" });
  const year = createdAt?.getFullYear();

  const name = storeUser?.displayName?.split(" ").slice(0, 2).join(" ");

  const isAdmin = user?.isAdmin;
  const isLoggedIn = !!user;

  const logout = () => {
    setUser(null);
  };

  const ClientMenu = () => {
    return (
      <UserWrap>
        <div className="user-avatar">
          <Image
            src={storeUser?.photoURL ? storeUser.photoURL : defaultProfile}
            alt="Abrir menu"
            height={50}
            width={50}
          />
        </div>

        <div className="loggedInfo">
          <div className="username">
            <span className="name">
              {name ? name : "usuário"}
              <IoMdArrowDropdown />
            </span>

            {createdAt && (
              <span>
                Membro desde {month} {year}
              </span>
            )}
          </div>
        </div>

        <ul className="dropdown">
          <li>
            <Link href="/cliente">
              <FaTrophy /> Meus Pedidos
            </Link>
          </li>
          <li>
            <Link onClick={logout} href={"/"}>
              <IoIosExit /> Sair
            </Link>
          </li>
        </ul>
      </UserWrap>
    );
  };

  const AdminMenu = () => {
    return (
      <UserWrap>
        <div className="user-avatar">
          <Image
            src={storeUser?.photoURL ? storeUser.photoURL : defaultProfile}
            alt="Abrir menu"
            height={50}
            width={50}
          />
        </div>

        <div className="loggedInfo">
          <div className="username">
            <span className="name">
              {name ? name : "usuário"}
              <IoMdArrowDropdown />
            </span>

            {createdAt && (
              <span>
                Membro desde {month} {year}
              </span>
            )}
          </div>
        </div>

        <ul className="dropdown">
          <li>
            <Link href="/admin">
              <FaTrophy /> Área do Administrador
            </Link>
          </li>
          <li>
            <Link onClick={logout} href={"/"}>
              <IoIosExit /> Sair
            </Link>
          </li>
        </ul>
      </UserWrap>
    );
  };

  const HandleMenu = () => {
    if (isAdmin) {
      return <AdminMenu />;
    }
    return <ClientMenu />;
  };

  return (
    <AvatarContainer>
      {isLoggedIn ? (
        <HandleMenu />
      ) : (
        <>
          <CustomAcquireButton
            link="/login?register=true"
            customcolor="var(--tg-theme-primary)"
            fontSize={18}
            styleLogin={true}
          >
            <div className="registerButton">
              <div className="icon">
                <FaRegEdit />
              </div>
              <span>Registrar</span>
            </div>
          </CustomAcquireButton>

          <i className="iconeLogin">OU</i>

          <CustomAcquireButton
            link="/login"
            customcolor="var(--tg-theme-primary)"
            fontSize={18}
            styleLogin={true}
          >
            <div className="loginButton">
              <div className="icon">
                <FaLock />
              </div>
              <span>Login</span>
            </div>
          </CustomAcquireButton>
        </>
      )}
    </AvatarContainer>
  );
};
