import tokens from "@/tokens";
import styled from "styled-components";

export const UserWrap = styled.div`
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
  position: relative;
  margin-right: 50px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover .dropdown {
    visibility: visible;
    opacity: 1;
  }

  .user-avatar {
    float: left;
    position: relative;
    border-radius: 50%;
    background: var(--tg-theme-primary);
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loggedInfo {
    text-transform: uppercase;
    font-weight: 700;
    color: #eee;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 17px 5px 0px;
    margin: 5px 0 0 15px;
  }

  .user-avatar img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
  }

  .loggedInfo .name {
    font-size: 14px;
    font-weight: 800;
    color: #fff;
  }

  .loggedInfo .name svg {
    color: var(--tg-theme-primary);
  }

  .username span {
    font-size: 10px;
    font-weight: 500;
    margin-top: 3px;
    position: relative;
    display: block;
    opacity: 0.8;
    letter-spacing: 0;
  }

  .dropdown {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 79px;
    background: #2a2a34;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    visibility: hidden;
    opacity: 0;
    border: solid 2px rgba(0, 0, 0, 0.2);
    border-width: 1px 2px 2px 2px;
    z-index: 1050;
    box-sizing: content-box;
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .dropdown li {
    width: 100%;
    position: relative;
    float: left;
  }

  .dropdown li a {
    width: 100%;
    position: relative;
    float: left;
    padding: 3px 15px;
    line-height: 35px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    color: #dcdce0;
    text-decoration: none;
    width: 100%;
    font-weight: normal;
    font-size: 12px;
    gap: 5px;
  }

  .dropdown li a:hover {
    color: #ffffff;
    background: rgba(0, 0, 0, 0.3);
  }

  .dropdown li a svg {
    color: var(--tg-theme-primary);
    font-size: 16px;
  }

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    margin-right: 0;
    padding-left: 0;

    .loggedInfo {
      padding: 0;
    }
    ul.dropdown {
      margin-top: 0 !important;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: auto;

  .highlight {
    :hover {
      span {
        color: #000;
      }
    }
    span {
      color: #000;
    }
  }

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
    margin-top: 1rem;

    a {
      margin-top: 0;
    }
  }

  .discord-button {
    width: 150px;
    height: 40px;
    padding: 0.9rem;
    justify-content: center;

    span {
      font-weight: 400;
      font-size: 17px;
    }
  }

  .action {
    width: 70px;
    height: 40px;
    font-size: 14px;
    display: flex;
    place-content: center;
    font-family: var(--tg-heading-font-family);
  }

  .iconeLogin {
    position: relative;
    margin: 0 13px 0 12px;
    font-size: 12px;
    text-transform: uppercase;
    color: #ccc;
    font-weight: 700;
    display: flex;
    align-items: end;
    justify-content: center;
  }
`;
