import styled from "styled-components";
import tokens from "@/tokens";

export const WhatsAppContainer = styled.div`
  position: fixed;
  bottom: 55px;
  right: 16px;
  transition: all 500ms;
  z-index: 10;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    bottom: -2px;
    right: -3px;
  }

  &:hover {
    transform: scale(1.5);
  }

  img {
    cursor: pointer;
    object-fit: contain;
    display: flex;
  }
`;
